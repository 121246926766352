"use client"
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { YandexMetricaProvider } from 'next-yandex-metrica';

// Определяем интерфейс для контекста
interface YandexContextType {
    isLoaded: boolean;
}

// Создаем контекст с начальным значением null
const YandexContext = createContext<YandexContextType | null>(null);

interface YandexProviderProps {
    ya: number; // Замените any на более конкретный тип, если возможно
    children: ReactNode;
}

export const YandexProvider: React.FC<YandexProviderProps> = ({ ya, children }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // Проверяем, загружен ли скрипт Яндекс Метрики
        //@ts-ignore
        if (window.ym || !ya) {
            //@ts-ignore
            console.log("window.ym ", window.ym);
            setIsLoaded(true);
        }
    }, [ya]);

    return (
        <YandexContext.Provider value={{ isLoaded }}>
            <YandexMetricaProvider
                tagID={ya}
                initParameters={{ clickmap: true, trackLinks: true, accurateTrackBounce: true }}
            >
                {children}
            </YandexMetricaProvider>
        </YandexContext.Provider>
    );
};

export const useYandex = () => {
    const context = useContext(YandexContext);
    if (!context) {
        throw new Error("useYandex must be used within a YandexProvider");
    }
    return context;
};